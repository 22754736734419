/**
 * File Name childTheme.js
 **/
// ######################################################################


/**
 * childTheme
 **/
var childTheme = {

	/**
	 * init
	 **/
	init : function() {
        childTheme.showHideMenu();
        childTheme.primaryNav();
        childTheme.slickGallery();

        reframe('.layout-post-video iframe');
	},

    slickGallery : function() {
        jQuery('.image-slides').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.image-nav'
        });
        jQuery('.image-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.image-slides',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            prevArrow: '<span class="fa prev fa-arrow-circle-left"></span>',
            nextArrow: '<span class="fa next fa-arrow-circle-right"></span>'
        });
    },

    postGalleryOnClick : function() {
	    jQuery('.layout-post-gallery .slide').click(function(e) {
	        e.preventDefault();
	        var selected = jQuery(this).attr('data-index');
	        var gallery = jQuery('.layout-post-gallery').clone();
	        gallery.removeAttr('class').attr({
                'id' : 'modal-gallery'
            });

            jQuery('body').append(modalJs.getHtml());

            jQuery('#modal .modal-content').html(gallery);

            var width = jQuery('#modal .modal-content').width();

            var slider = jQuery('#modal-gallery').bxSlider({
                'mode': 'fade',
                'captions': true,
                'slideWidth': width,
                'infiniteLoop': false,
                'hideControlOnEnd': true,
                'pager': false,
                'controls': true,
                'nextText': '<span class="controller"><span class="fa fa-chevron-right"></span></span>',
                'prevText': '<span class="controller"><span class="fa fa-chevron-left"></span></span>'
            });

            slider.goToSlide(parseInt(selected));

            jQuery('#modal').fadeIn(modalJs.fadeInSpeed);
            jQuery('#modal .close').click(modalJs.closeModal);

            modalJs.acitvateCloseModal();
            modalJs.pauseScrolling();

        });
    },

    showHideMenu : function() {
	    jQuery(".trigger-menu-open").click(function(e) {
	        e.preventDefault();
            jQuery('#section-menu-bg').addClass('show');
            jQuery('#section-menu').addClass('show');
            jQuery('body').css({
                'overflow': 'hidden'
            });
        });
	    childTheme.acitvateCloseMenu();
    },

    closeMenu : function() {
        jQuery('#section-menu-bg').removeClass('show');
        jQuery('#section-menu').removeClass('show');
        jQuery('body').css({
            'overflow': 'visible'
        });
    },

    acitvateCloseMenu : function() {
        jQuery(".trigger-menu-close").click(function(e) {
            e.preventDefault();
            childTheme.closeMenu();
        });
        jQuery(document).mousedown(function(e) {
            var clicked = jQuery(e.target).attr('id');
            if ( clicked == 'trigger-close' ) {
                e.preventDefault();
                childTheme.closeMenu();
            } else {
                return;
            }
        });
        jQuery(document).keydown(function(e) {
            switch (e.which) {
                case 27 :
                    e.preventDefault();
                    childTheme.closeMenu();
                    break;
            }
        });
    },

    primaryNav : function() {
        var options = {};
        var element = jQuery('#primary-menu ul.menu');
        new Foundation.AccordionMenu(element, options);
    }
};

/**
 * bookLanding
 **/
var bookLanding = {

	/**
	 * init
	 **/
	init : function() {
		this.slideShow();
	},

	/**
	 * slideShow
	 **/
    slideShow : function() {
    	var options = {
            'mode': 'horizontal',
            'infiniteLoop': false,
            'pager': true,
			'controls': true,
			'nextText': '<span class="fa fa-chevron-right"></span>',
			'prevText': '<span class="fa fa-chevron-left"></span>',
			'minSlides': 1,
			'maxSlides': 6,
			'moveSlides': 1,
			'slideWidth': 150,
            'hideControlOnEnd': true,
            'touchEnabled': false
        };
        jQuery('#book-landing-wrapper .slider').bxSlider(options);
	}

};

/**
 * jQuery
 **/
// needs to be added to gruntfile.js jQuery(document).foundation();
jQuery(document).ready(function() {
    bookLanding.init();
    childTheme.init();
});

/**
 * File Name childTheme.js
 * @license GPL v2 - http://www.gnu.org/licenses/old-licenses/gpl-2.0.html
 **/
// ######################################################################


/**
 * modalJs
 **/
var modalJs = {


	fadeInSpeed : 200,
	fadeOutSpeed : 200,
	example : function() {
		jQuery('.modal-video').click(function(e) {
			e.preventDefault();

			jQuery('body').append( '<div id="modal" class="modal-iframe"><div class="inside-wrapper"><span class="icon-close close"></span><div id="modal-container"><div class="modal-content"></div></div></div></div>' );

			jQuery('#modal .modal-content').html('stuff here...');

			jQuery('#modal').fadeIn(modalJs.fadeInSpeed);
			jQuery('#modal .close').click(modalJs.closeModal);

			modalJs.acitvateCloseModal();
			modalJs.pauseScrolling();
		});
	},

	getHtml : function() {
		return '<div id="modal"><div class="inside-wrapper"><span class="fa fa-close close"></span><span class="modal-title"></span><div id="modal-container"><div class="modal-content"></div></div></div></div>';
	},


	acitvateCloseModal : function() {
		jQuery(document).mousedown(function(e) {
			var clicked = jQuery(e.target).attr('id');
			if ( clicked == 'modal' ) {
				modalJs.closeModal();
			} else {
				return;
			}
		});
		jQuery(document).keydown(function(e) {
			switch (e.which) {
				case 27 :
					modalJs.closeModal();
					break;
			}
		});
	},

	closeModal : function() {
		jQuery('#modal').fadeOut( modalJs.fadeOutSpeed, function() {
			modalJs.unPauseScrolling();
			jQuery('#modal').remove();
		});
	},

	pauseScrolling : function() {
		jQuery('body').css({
			'overflow' : 'hidden'
		});
	},

	unPauseScrolling : function() {
		jQuery('body').css({
			'overflow' : 'auto'
		});
	}

};
